import { useEffect, useState } from 'react'

import axios from 'axios'
import { BaserowLogin } from 'types/graphql'

import { useParams } from '@redwoodjs/router'
import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import Spinner from 'src/components/Library/Loading'

export const QUERY = gql`
  query BaserowLogin {
    loginToBaserow {
      jwt
      refreshToken
    }
  }
`

export const Loading = () => <Spinner />

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({
  loginToBaserow: { refreshToken, jwt },
}: CellSuccessProps<BaserowLogin>) => {
  const { linkData, linkType } = useParams()
  const [notificationUrl, setNotificationUrl] = useState('')

  let baserowUrl = `/login?token=${refreshToken}`
  if (linkType === 'baselink' || linkType === 'form') {
    baserowUrl = linkData
  }

  useEffect(() => {
    if (refreshToken) {
      //document.cookie = `refreshToken=${refreshToken}; domain=baserow.stafflink.com.au; path=/`
      document.cookie = `jwt_token=${jwt}; domain=baserow.stafflink.com.au; path=/`
    }
  }, [refreshToken])

  useEffect(() => {
    const getNotificationUrl = async () => {
      const brApi = axios.create({
        baseURL: `${process.env.BASEROW_URL}/api`,
        headers: {
          Authorization: `JWT ${jwt}`,
          'Content-Type': 'application/json',
        },
      })
      const getNotificationResponse = await brApi.patch(
        `/notifications${linkData}/`,
        {
          read: true,
        },
      )
      const notificationData = getNotificationResponse.data.data
      setNotificationUrl(
        `${process.env.BASEROW_URL}/database/${notificationData.database_id}/table/${notificationData.table_id}/row/${notificationData.row_id}`,
      )
    }
    if (linkType === 'notification') {
      getNotificationUrl()
    }
  }, [linkData, linkType])

  if (linkType === 'notification' && !notificationUrl) {
    return <Spinner />
  }

  if (linkType === 'notification' && notificationUrl) {
    return (
      <iframe
        id={'baserow-frame'}
        title={'baserow-frame'}
        width={'100%'}
        height={'100%'}
        src={notificationUrl}
      />
    )
  }

  if (linkType !== 'notification') {
    return (
      refreshToken && (
        <iframe
          id={'baserow-frame'}
          title={'baserow-frame'}
          width={'100%'}
          height={'100%'}
          src={`${process.env.BASEROW_URL}${baserowUrl}`}
        />
      )
    )
  }
}
